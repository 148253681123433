import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"
import Blog from "../components/blog"
import SEO from "../components/seo"
import { globalHistory } from "@reach/router"

class BlogPage extends React.Component {
  render() {
    const { pageContext } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressPost {
              nodes {
                excerpt
                featured_media {
                  id
                  alt_text
                  caption
                  description
                  link
                  source_url
                  title
                }
                content
                id
                path
                slug
                title
                status
                link
                date(formatString: "MMMM DD, YYYY")
                author {
                  id
                  url
                  name
                  link
                  path
                  slug
                }
                categories {
                  taxonomy {
                    name
                    slug
                    id
                  }
                  count
                  id
                  link
                  name
                  slug
                  path
                }
                tags {
                  count
                  id
                  link
                  name
                  slug
                  path
                }
              }
            }
            allWordpressCategory {
              totalCount
              nodes {
                slug
                path
                name
                link
                taxonomy {
                  id
                  slug
                  name
                }
                count
                id
              }
            }
            contentfulV4BlogPage {
              backgroundImage {
                fluid(maxWidth: 1300, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                fixed {
                  src
                }
                file {
                  url
                }
                title
              }
              name
              linkText
              by
              datePostedText
              metaDescription
              keywords
              browserPageTitle
              slug
              showFooterExtended
              footerExtended {
                content {
                  id
                  link
                  linkText
                  title
                  description
                  icon {
                    fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                    id
                  }
                  arrow {
                    fluid(maxWidth: 20, quality: 100) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                    id
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const mod = data.allWordpressPost
          const pageData = data.contentfulV4BlogPage
          const allWordpressCategory = data.allWordpressCategory
          return (
            <div>
              <SEO
                description={pageData.metaDescription}
                title={pageData.browserPageTitle}
                keywords={pageData.keywords}
              />
              <Blog
                mod={mod}
                location={globalHistory.location}
                pageData={pageData}
                categories={allWordpressCategory}
                blogEndPoint={pageContext.blogEndPoint}
              />
              <Footer
                showFooterExtended={pageData.showFooterExtended}
                footerExtendedContent={pageData.footerExtended}
              />
            </div>
          )
        }}
      />
    )
  }
}

export default BlogPage
