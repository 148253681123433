import React from "react"
import searchHeaderStyle from "./style.module.scss"

const SearchHeader = ({ filter, clearFilter }) => (
  <div
    className={
      filter
        ? searchHeaderStyle.searchResults
        : searchHeaderStyle.searchResultsNone
    }
  >
    <span className={searchHeaderStyle.searchResultsText}>
      {filter ? filter.key.toUpperCase() : ""} RESULTS FOR:
    </span>
    <span className={searchHeaderStyle.searchResultsFrom}>
      {filter ? filter.name.toUpperCase() : ""}
    </span>
    <span className={searchHeaderStyle.pipe}>|</span>

    <button
      className={
        filter
          ? searchHeaderStyle.closeResults
          : searchHeaderStyle.closeResultsNone
      }
      onClick={() => clearFilter()}
    >
      CLEAR
    </button>
  </div>
)

export default SearchHeader
