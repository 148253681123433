import React from "react"
import paginationStyle from "./style.module.scss"

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage)
  const getNumbersArray = [...Array(totalPages).keys()].map(x => ++x)
  let pageNumbers = getNumbersArray
  const getDecimalPages = [
    ...Array(Math.floor(pageNumbers.length / 10)).keys(),
  ].map(x => ++x * 10)

  if (totalPages > 5) {
    pageNumbers = getNumbersArray.slice(0, 5)
  }
  if (currentPage >= 4 && totalPages > 5) {
    const previousDecimals = getDecimalPages.filter(
      decimal => currentPage - 4 > decimal
    )
    const currentDecimals = getNumbersArray.slice(
      currentPage - 3,
      currentPage + 2
    )
    const postDecimals = getDecimalPages.filter(
      decimal => currentPage + 4 < decimal
    )

    pageNumbers = [...previousDecimals, ...currentDecimals, ...postDecimals]
  }

  const scrollTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className={paginationStyle.header}>
      <div className={paginationStyle.navigation}>
        <div className={paginationStyle.currently}>
          <p className={paginationStyle.currentlyAt}>
            Page
            <span className={paginationStyle.currentPage}>{currentPage}</span>
            of
            <span className={paginationStyle.totalPages}>{totalPages}</span>
          </p>
        </div>
        <div className={paginationStyle.navigationNumbers}>
          <button
            className={
              currentPage === 1
                ? paginationStyle.none
                : paginationStyle.toFirtsPage
            }
            onClick={() => {
              paginate(1)
              scrollTop()
            }}
          >
            <span className={paginationStyle.arrows}>«</span>
            First
          </button>
          <button
            className={
              currentPage === 1
                ? paginationStyle.none
                : paginationStyle.toFirtsPage
            }
            onClick={() => {
              paginate(currentPage - 1)
              scrollTop()
            }}
          >
            <span className={paginationStyle.arrows}>«</span>
          </button>
          <ul className={paginationStyle.listNumbers}>
            {pageNumbers.map(number => (
              <li key={number}>
                <button
                  className={
                    currentPage === number
                      ? paginationStyle.numbersActive
                      : paginationStyle.numbers
                  }
                  onClick={() => {
                    paginate(number)
                    scrollTop()
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
          <button
            className={
              currentPage === totalPages
                ? paginationStyle.none
                : paginationStyle.toLastPage
            }
            onClick={() => {
              paginate(currentPage + 1)
              scrollTop()
            }}
          >
            <span className={paginationStyle.arrows}>»</span>
          </button>
          <button
            className={
              currentPage === totalPages
                ? paginationStyle.none
                : paginationStyle.toLastPage
            }
            onClick={() => {
              paginate(totalPages)
              scrollTop()
            }}
          >
            Last
            <span className={paginationStyle.arrows}>»</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pagination
