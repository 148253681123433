import React from "react"
import postSummaryStyle from "./style.module.scss"
import { Link } from "gatsby"

const PostSummary = ({ data, pageData, filterAuthors }) => {
  return (
    <div className={`${postSummaryStyle.postSummary}`}>
      {data.map(current => (
        <div className={postSummaryStyle.postContainer} key={current.id}>
          <div className={postSummaryStyle.header}>
            <h2 className={postSummaryStyle.headerTitle}>
              <Link
                className={postSummaryStyle.headerTitleLink}
                to={current.path}
                title={current.linkText}
                dangerouslySetInnerHTML={{ __html: current.title }}
              />
            </h2>
            <div className={postSummaryStyle.headerDatePosted}>
              <div className={postSummaryStyle.datePostedText}>
                {pageData.datePostedText}
              </div>
              <div className={postSummaryStyle.datePostedDate}>
                {current.date}
              </div>
            </div>
          </div>
          <div className={postSummaryStyle.postedBy}>
            <span className={postSummaryStyle.byText}>{pageData.by}</span>
            <button
              className={postSummaryStyle.byAuthorLink}
              onClick={() =>
                filterAuthors("author", current.author.id, current.author.name)
              }
            >
              {current.author.name}
            </button>
          </div>

          {current.featured_media ? (
            <div className={postSummaryStyle.summary}>
              <div className={postSummaryStyle.summaryImageContainer}>
                <img
                  className={postSummaryStyle.summaryImage}
                  src={current.featured_media.source_url}
                  alt={current.featured_media.source_url}
                />
              </div>
              <div className={postSummaryStyle.summaryDescription}>
                <div
                  className={postSummaryStyle.summaryText}
                  dangerouslySetInnerHTML={{ __html: current.excerpt }}
                />
                <Link
                  className={postSummaryStyle.summaryLink}
                  to={current.path}
                  title={current.linkText}
                >
                  {pageData.linkText}
                </Link>
              </div>
            </div>
          ) : (
            <div className={postSummaryStyle.summary}>
              <div
                className={`${postSummaryStyle.summaryDescription} ${postSummaryStyle.summaryDescriptionFullWidth}`}
              >
                <div
                  className={postSummaryStyle.summaryText}
                  dangerouslySetInnerHTML={{ __html: current.excerpt }}
                />

                <Link
                  className={postSummaryStyle.summaryLink}
                  to={current.path}
                  title={current.linkText}
                >
                  {pageData.linkText}
                </Link>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default PostSummary
