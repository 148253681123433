import React from "react"
import { Input } from "antd"
import nothingFoundStyles from "./style.module.scss"

const { Search } = Input

const NothingFound = ({ filterSearch }) => (
  <div className={nothingFoundStyles.nothingContainer}>
    <h2 className={nothingFoundStyles.title}>NOTHING FOUND</h2>
    <p className={nothingFoundStyles.text}>
      Sorry, but nothing matched your search criteria. Please try again with
      some different keywords.
    </p>
    <Search
      placeholder="SEARCH"
      onSearch={value => {
        filterSearch("search", value, value)
      }}
      style={{ width: 300 }}
    />
  </div>
)

export default NothingFound
