import React, { useState } from "react"
import { navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import PostSummary from "./post-summary"
import Pagination from "./pagination"
import Categories from "./categories"
import SearchBar from "./search"
import blogStyle from "./style.module.scss"
import NothingFound from "./nothing-found"
import SearchHeader from "./search-header"

const POST_PER_PAGE = 5

const Blog = ({ mod, pageData, categories, location }) => {
  const filterFromProp = location && location.state && location.state.filter
  const posts = mod.nodes
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState(null)

  if (filterFromProp !== filter) {
    setCurrentPage(1)
    setFilter(filterFromProp)
  }

  const clearFilter = () => {
    navigate("/", {
      state: {
        filter: null,
      },
    })
  }

  const filterCallback = (key, val, name) => {
    navigate("/", {
      state: {
        filter: {
          key,
          val,
          name,
        },
      },
    })
  }

  let filteredPosts = posts
  if (filter) {
    filteredPosts = posts.filter(currentPost => {
      switch (filter.key) {
        case "tags":
          if (currentPost.tags) {
            return currentPost.tags.find(tag => tag.id === filter.val)
          } else {
            return ""
          }
        case "category":
          return currentPost.categories.find(
            category => category.id === filter.val
          )
        case "author":
          return currentPost.author.id === filter.val

        case "search":
          const searchCategories = currentPost.categories.find(category =>
            category.name.toLowerCase().includes(filter.val.toLowerCase())
          )

          const searchAuthors = currentPost.author.name
            .toLowerCase()
            .includes(filter.val.toLowerCase())

          const searchTitle = currentPost.title
            .toLowerCase()
            .includes(filter.val.toLowerCase())

          const searchExcerpt = currentPost.excerpt
            .toLowerCase()
            .includes(filter.val.toLowerCase())

          const searchArticles = currentPost.content
            .toLowerCase()
            .includes(filter.val.toLowerCase())

          return (
            searchExcerpt ||
            searchAuthors ||
            searchTitle ||
            searchCategories ||
            searchArticles
          )
        default:
          return true
      }
    })
  }

  const indexOfLastPost = currentPage * POST_PER_PAGE
  const indexOfFirstPost = indexOfLastPost - POST_PER_PAGE
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  return (
    <BackgroundImage
      Tag="section"
      className="blogPageBg"
      fluid={pageData.backgroundImage.fluid}
      style={{
        backgroundSize: `100% auto`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `top`,
        marginTop: ``,
      }}
    >
      <div className="container">
        <div className={blogStyle.pageStructure}>
          {filteredPosts.length !== 0 ? (
            <div className={blogStyle.content}>
              <SearchHeader filter={filter} clearFilter={clearFilter} />
              <PostSummary
                data={currentPosts}
                pageData={pageData}
                filterAuthors={filterCallback}
              />
              <Pagination
                postsPerPage={POST_PER_PAGE}
                totalPosts={filteredPosts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          ) : (
            <div className={blogStyle.content}>
              <NothingFound filterSearch={filterCallback} />
            </div>
          )}

          <div className={blogStyle.aside}>
            <SearchBar filterSearch={filterCallback} />
            <Categories
              categories={categories}
              filterCategories={filterCallback}
            ></Categories>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Blog
